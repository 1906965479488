import { useSlider } from 'components/_hooks/useSlider'
import { generateID } from 'components/_utils/idUtils'
import { Img } from 'components/basic/Img'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { Type } from 'components/Type'
import { FC } from 'react'
import { useCurrency } from 'stores/prices'
import styles from './ExploreProducts.module.scss'
import { ExploreProductsProps, ProductCardProps } from './ExploreProducts.types'

export const ExploreProductsConnected = () => {
	const currency = useCurrency()
	const products = getDefualtProducts()

	if (currency !== 'USD') {
		return null
	}

	return <ExploreProducts products={products} />
}

export const ExploreProducts: FC<ExploreProductsProps> = (props) => {
	const { title = 'Explore our products', products = [] } = props

	const [sliderRef, currentSlide, goToSlide] = useSlider()

	const onPrevClick = () => {
		goToSlide(Math.max(0, currentSlide - 1))
	}
	const onNextClick = () => {
		goToSlide(Math.min(products.length - 1, currentSlide + 1))
	}

	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<Type.Headline2>{title}</Type.Headline2>
				<ul
					// ref={sliderRef}
					className={styles.product_list}
				>
					{products.map((product) => (
						<li key={product.title}>
							<ProductCard {...product} />
						</li>
					))}
				</ul>
			</div>
		</section>
	)
}

const SliderControls: FC<{
	currentSlide: number
	totalSlides: number
	onPrevClick: () => void
	onNextClick: () => void
	goToSlide: (slide: number) => void
}> = (props) => {
	const { currentSlide, totalSlides, onPrevClick, onNextClick, goToSlide } = props
	return (
		<div className={styles.slider_controls}>
			<div className={styles.slider_dots}>
				{new Array(totalSlides).fill(0).map((_, i) => (
					<Button.Empty
						key={i}
						id={`explore-product-dot-${i}`}
						onClick={() => goToSlide(i)}
						disabled={i === currentSlide}
					/>
				))}
			</div>
			<div className={styles.slider_buttons}>
				<Button.Empty
					id={`explore-product-prev`}
					onClick={onPrevClick}
					disabled={currentSlide === 0}
				>
					<Icon
						name={'ChevronLeftDark'}
						color={'black'}
					/>
				</Button.Empty>
				<Button.Empty
					id={`explore-product-next`}
					onClick={onNextClick}
					disabled={currentSlide === totalSlides - 1}
				>
					<Icon
						name={'ChevronRightDark'}
						color={'black'}
					/>
				</Button.Empty>
			</div>
		</div>
	)
}

const ProductCard: FC<ProductCardProps> = (props) => {
	const { img, title, description, cta } = props
	return (
		<div className={styles.product_card}>
			<Img
				src={img.src}
				alt={img.alt}
				objectFit={'cover'}
				sources={img.mobileSrc ? [{ src: img.mobileSrc, mediaQuery: '(max-width: 1024px)' }] : undefined}
			/>
			<div className={styles.product_card_content}>
				<header>
					<Type.Headline4 as={'h3'}>{title}</Type.Headline4>
					<Type.Body2>{description}</Type.Body2>
				</header>
				<div>
					<Button.Dark
						id={`${generateID(title)}-explore-cta`}
						href={cta.href}
						onClick={cta.onClick}
					>
						{cta.text}
					</Button.Dark>
				</div>
			</div>
		</div>
	)
}

const getDefualtProducts = (): ProductCardProps[] => {
	const cards: ProductCardProps[] = []

	cards.push({
		title: 'Sleep Elixir',
		description: 'Sleep supplement to fall asleep faster',
		cta: {
			text: 'Shop now',
			href: '/product/sleep-elixir',
		},
		img: {
			src: 'https://res.cloudinary.com/eightsleep/image/upload/sleep-elixir-callout-cta_lyat2z.png',
			alt: 'Sleep Elixir',
		},
	})

	cards.push({
		title: 'Sleep Essentials Bundle',
		description: 'Breathable sheets and pillows that pair best with the Pod',
		cta: {
			text: 'Shop now',
			href: '/product/the-sleep-essentials-bundle',
		},
		img: {
			src: 'https://res.cloudinary.com/eightsleep/image/upload/sleep-essentials-bundle-callout-cta_fai7xc.png',
			alt: 'Sleep Essentials Bundle',
		},
	})

	return cards
}
